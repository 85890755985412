export class InfoAlertMessage {

    /**
     * Constructor
     *
     * @param message
     * @param details
     * @param scrollTop
     * @param css
     */
    constructor(message, details = '', scrollTop = true, css = 'alert alert-info alert-styled-left alert-dismissible') {
        this.visible    = true;
        this.message    = message;
        this.details    = details;
        this.scrollTop  = scrollTop;
        this.attributes = {
            class: css,
        };
    }

}
